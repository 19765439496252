.sidebar {
  width: 250px;
  background-color: #f7f9fd;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow-y: auto;
}

.sidebar__header {
  @apply flex items-center justify-center mt-9;
  font-size: 28px;
  font-weight: 600;
  padding: 20px;
  /* margin-top: 30px; */
}
.sidebar__logo {
  font-size: 40px;
}
.sidebar__header > * {
  margin: 0 5px;
}

.sidebar__options {
  margin-top: 30px;
}
