.overviewheader {
  border-color: rgba(31, 33, 39, 0.1);
  @apply flex flex-col items-center justify-center p-5 border-b-2;
}
#overviewheader__logo {
  font-size: 7em;
}

#overviewheader__name {
  font-size: 3em;
}

#overviewheader__description {
  color: rgba(31, 33, 39, 0.58);
  font-weight: 400;
  font-size: 1.3em;
}

#overviewheader__email {
  color: rgba(31, 33, 39, 0.58);
  font-weight: 300;
  font-size: 0.9em;
}
