.app {
  display: flex;
  align-items: flex-start;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #fafbfd;
}
.app__content {
  position: relative;
  margin-left: 250px;
  @apply px-10 pb-10 w-full flex flex-col;
}
