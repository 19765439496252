.sidebarOption {
  color: rgba(32, 34, 40, 0.6);
  padding-left: 3.6rem;
  @apply flex items-center cursor-pointer py-5 border-4 border-transparent;
}
.sidebarOption:hover {
  background-color: rgba(57, 84, 255, 0.05);
  border-left: 4px solid #3954ff;
}

.sidebarOption--active {
  background-color: rgba(57, 84, 255, 0.05);
  border-left: 4px solid #3954ff;
}

.sidebarOption__icon {
  @apply mr-5;
}
.sidebarOption > h3 {
  font-weight: 500;
  font-size: 15px;
}
