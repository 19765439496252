.cardfirm {
  transition-timing-function: ease;
  transition: all 0.3s;
  @apply bg-white rounded-lg overflow-hidden p-0 pt-5 flex flex-col;
  box-shadow: 0px 13px 18px rgba(57, 84, 255, 0.04);
}
.cardfirm_nohover {
  @apply bg-white rounded-lg overflow-hidden p-0 pt-5 flex flex-col;
  box-shadow: 0px 13px 18px rgba(57, 84, 255, 0.04);
}
.cardfirm:hover {
  transform: scale(1.04);
  box-shadow: 0px 18px 23px rgba(34, 51, 158, 0.096);
}

.cardfirm__header {
  @apply flex  items-center justify-between text-sm mb-7;
}

.cardfirm__options {
  /* display: flex; */
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 15px;
}
.cardfirm__options:after {
  content: "•••";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  font-size: 8px;
  letter-spacing: 2px;
  margin-top: 2px;
}

.cardfirm__component {
  @apply h-full;
}
