.header {
  @apply flex justify-between items-center pt-1 mb-10 text-xl;
  margin-top: 55px;
}
.header__right {
  @apply flex items-center;
}

.header__right > * {
  @apply mx-4;
}
