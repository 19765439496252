.team_member {
  @apply flex max-h-16 items-center justify-between m-2;
}

.team_member__info {
  @apply flex;
}
.team_member__image {
  max-height: 60px;
  max-width: 60px;
  @apply rounded overflow-hidden;
}
.team_member__description {
  @apply ml-5 mt-1;
}
.team_member__name {
  @apply text-xl;
  font-weight: 500;
}
.team_member__designation {
  @apply text-base;
  font-weight: 400;
}
.team_member__rating {
  @apply px-4 py-1 m-4 mr-5 rounded-lg;
  color: #3954ff;
  font-weight: 600;
  background-color: rgba(57, 84, 255, 0.06);
}
